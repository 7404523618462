import {Route, Routes} from 'react-router-dom';
import Home from './Home'
import Contact from "./Contact";
import Portfolio from "./portfolio";
import About from "./About";
const Views = () =>{
    return(
        <Routes>
            <Route index element={<Home />}/>
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<div>404 Not Found </div>} />
        </Routes>
    )
}

export default Views;