import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useCallback, useEffect, useState } from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function PortfolioModalNoYT(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

  const [data, setData] = useState([]);
  const getData = useCallback(async () => {
    const response = await fetch(props.file, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const data = await response.json();
    setData(data);
    console.log(props.icon)
  }, [props.file]);
  useEffect(() => {
    getData().catch((e) => {
      console.debug(e);
      console.log(props.file);
    });
  }, [getData]);

  return (
        <div>
          {data.map((item)=>(
            <div>
            <Button style={{
              backgroundImage: `url(${props.icon})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition:"center",
              backgroundColor: "darkblue",
                margin: "auto",
                borderRadius: 2,
                height: 300,
                width: 300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                    boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.5)",
                }
            }} onClick={handleOpen}></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      {item.title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {item.text}
                    </Typography>
                  <div style={{ overflow: "hidden",
                    paddingBottom: "56.25%",
                    position: "relative",
                    height: "0"
                  }}>
                    <a href={item.link}> The repo can be viewed here.</a>
                  </div>
                </Box>
            </Modal>
            </div>
          ))}
        </div>
    );
}

export default PortfolioModalNoYT;