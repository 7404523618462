import EmailComp from "./EmailComp";
import * as React from "react";
import Header from "./Header"
import { styled } from "@mui/system";
function contact(){
  const StyledMain= styled("main", {
    name: "StyledContainer",
    slot: "Root"
  })({
    display: "flex",
    alignItems:"center top",
    paddingTop: "5%",
    minHeight:"100vh",
    background: "linear-gradient( #cc99ff, #d9b3ff);"

  });
return(
    <div>
      <Header />
      <StyledMain>
      <EmailComp />
      </StyledMain>

    </div>
);
}

export default contact;