import React from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import HomeCard from "./Homecard";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {styled} from "@mui/system";
import "../StyleSheets/Main.css"

const theme = createTheme();

const StyledContainer= styled(Container, {
    name: "StyledContainer",
    slot: "Wrapper"
})({
  display: "flex",
  alignItems:"center top",
  minHeight:"100vh",
  paddingBottom:"20%"


});

const StyledMain= styled("main", {
    name: "StyledContainer",
    slot: "Root"
})({
  background: "linear-gradient( #cc99ff, #d9b3ff);",
  alignItems:"center",
  textAlign:"center"
});

export default class Main extends React.Component {
    render(){
        return(

                <StyledMain>
                  <div style={{width:"100%", paddingTop:"10%", alignItems:"center"}}>
                    Hi, I'm a software developer based in West Yorkshire who specialises in Web and Games development. Please feel free to have a look around or send me an email if you have any questions.
                  </div>
                    <StyledContainer>
                        <Grid container spacing={8} alignItems={"center"}>
                            <Grid item xs={12} sm={6} md={4} minWidth={400} paddingRight={10} maxWidth={400} margin={"auto"}>
                                <HomeCard Title={"Portfolio"} Path={"/portfolio"} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} minWidth={400} paddingRight={10} maxWidth={400} margin={"auto"}>
                                <HomeCard Title={"Contact Me"} Path={"/contact"}/>
                            </Grid>
                        </Grid>
                    </StyledContainer>
                </StyledMain>

        );
    }
}