import * as React from "react";
import emailjs from 'emailjs-com'
import {
    Box,
    Button,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {useRef, useState} from "react";
function EmailComp() {
        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [message, setMessage] = useState('');
        const [emailSent, setEmailSent] = useState(false);

        const submit = () => {
            if (name && email && message) {
                const serviceId = 'service_uwx253h';
                const templateId = 'template_lkf0xjq';
                const userId = 'VWIeVaXwN_HOp0Qc-';
                const templateParams = {
                    name,
                    email,
                    message
                };

                emailjs.send(serviceId, templateId, templateParams, userId)
                    .then(response => console.log(response))
                    .then(error => console.log(error));

                setName('');
                setEmail('');
                setMessage('');
                setEmailSent(true);
            } else {
                alert('Please fill in all fields.');
            }
        }

        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    gap: 4,
                    marginY: 4,
                    paddingX: 2,
                    marginX: "auto",
                    width: "100%",
                    maxWidth: "sm",
                }}
            >
                <input type="text" placeholder="Your Name" value={name} onChange={e => setName(e.target.value)}/>
                <input type="email" placeholder="Your email address" value={email}
                       onChange={e => setEmail(e.target.value)}/>
                <textarea placeholder="Your message" value={message}
                          onChange={e => setMessage(e.target.value)}></textarea>
                <button onClick={submit}>Send Message</button>
            </Box>
        );
}

export default EmailComp;