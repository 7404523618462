import * as React from "react";
import Header from "./Header"
import Footer from "./Footer"
import PortfolioModal from"./PortfolioModal"
import PortfolioModalNoYT from "./PortfolioModalNoYT";
import Grid from "@mui/material/Grid";
import {createTheme} from "@mui/material/styles";
import {styled} from "@mui/system";
import Container from "@mui/material/Container";
function contact(){
    const theme = createTheme();

    const StyledContainer= styled(Container, {
        name: "StyledContainer",
        slot: "Wrapper"
    })({
        maxWidth: "66%",
        display: "flex",
        flexWrap: "wrap",

    });

    const StyledMain= styled("main", {
        name: "StyledContainer",
        slot: "Root"
    })({
        display: "flex",
        alignItems:"center",
        minHeight:"100vh",
        background: "linear-gradient( #cc99ff, #d9b3ff);"

    });
    return(
        <div>
            <Header />
            <StyledMain>
                <StyledContainer>
                    <Grid container spacing={8} paddingBottom={15} paddingTop={10} alignItems={"center"}>
                        <Grid item xs={12} sm={6} md={4} minWidth={400} paddingRight={10} margin={"auto"}>
                            <PortfolioModal file={"./LethalDeal.json"} icon={"./Lethaldeal2.png"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} minWidth={400} paddingRight={10} margin={"auto"}>
                            <PortfolioModal file={"./cuckoo.json"} icon={"./cuckoo.png"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} minWidth={400} paddingRight={10} margin={"auto"}>
                            <PortfolioModalNoYT file={"./DecklistComponent .json"} icon={"./ReactComp.png"} />
                        </Grid>
                    </Grid>
                </StyledContainer>
            </StyledMain>
        </div>
    );
}

export default contact;