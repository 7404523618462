import React from "react";
import Views from "./Views"
import "../StyleSheets/App.css"
import {BrowserRouter} from "react-router-dom";


export default class App extends React.Component {
  render()
  {
    return (
        <BrowserRouter>
          <Views />
        </BrowserRouter>
    )
  }
}


