import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from "react-router-dom";

const pages = ['Home', 'Portfolio', 'Contact'];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="fixed" style={{backgroundColor:"White", opacity:"50%"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <Link to={"/"}>
                <MenuItem key="Home">
                  <Typography textAlign="center">Home</Typography>
                </MenuItem>
              </Link>

              <Link to={"/portfolio"}>
              <MenuItem key="Portfolio">
                <Typography textAlign="center">Portfolio</Typography>
              </MenuItem>
              </Link>

              <MenuItem key="Contact Me" onClick={handleCloseNavMenu}>
                <Typography textAlign="center">Contact Me</Typography>
              </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
            }}
          >
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <MenuItem key="Home">
              <Typography textAlign="center">Home</Typography>
            </MenuItem>
             <Link to={"/"}>
              <Button
                key="Home"
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                Home
              </Button>
               </Link>
            ))}
            <MenuItem>
              <Typography textAlign="center">Portfolio</Typography>
            </MenuItem>
            <Link to={"/portfolio"}>
              <Button
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                Portfolio
              </Button>
            </Link>
            ))}
            <MenuItem>
              <Typography textAlign="center">Contact</Typography>
            </MenuItem>
            <Link to={"/contact"}>
              <Button
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                Contact
              </Button>
            </Link>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;