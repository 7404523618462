import Main from './Main'
import React from "react";
import "../StyleSheets/App.css"
import Footer from "./Footer";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./Header"
const theme = createTheme();
export default class Home extends React.Component {
    render()
    {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Header/>
                <Main/>
            </ThemeProvider>
        );
    }
}