import * as React from "react";
import Header from "./Header"
import { styled } from "@mui/system";

function About(){
  const StyledMain= styled("main", {
    name: "StyledContainer",
    slot: "Root"
  })({
    display: "flex",
    alignItems:"center top",
    paddingTop: "5%",
    minHeight:"100vh",
    background: "linear-gradient( #cc99ff, #d9b3ff);"

  });
  return(
    <div>
      <Header />
      <StyledMain>
        Hi, I'm a software developer based in West Yorkshire who specialises in Web and Games development. Please feel free to have a look around or send me an email if you have any questions.
      </StyledMain>

    </div>
  );
}

export default About;