import * as React from "react";
import Card from "@mui/material/Card";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const StyledCard = styled(Card, {
    name: "StyledCard",
    slot: "Wrapper"
})({
    background: "linear-gradient( #cc99ff, #d9b3ff);",
    height: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.5)",

    }
});

export default function StyledPaperExample(props) {
    return (

        <Link to={props.Path}>
            <StyledCard>
             {props.Title}
        </StyledCard>
        </Link>


    );
}

