import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Link from "@mui/material/Link";
import {styled} from "@mui/system";

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const StyledBox = styled(Box, {
    name: "StyledBox",
    slot: "Wrapper"
})({
    backgroundColor: "red",
    bottom: 0,
    width: "100%",
    position: "fixed"
});
export default class footer extends React.Component {

    render() {
        return(
        <StyledBox component="footer">
            <Typography variant="h6" align="center" gutterBottom>
                Footer
            </Typography>
            <Typography
                variant="subtitle1"
                align="center"
                color="text.secondary"
                component="p"
            >
                Something here to give the footer a purpose!
            </Typography>
            <Copyright/>
        </StyledBox>
        );
    }
}